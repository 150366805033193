import {
  Input,
  InputMasked,
  OpenClosedStates,
  Phone,
  RadioGroup,
  Select,
  Toggle,
  LegalGuardianFields,
} from '@chiroup/components';
import {
  ClinicCaseType,
  CommunicationMethodTypes,
  COUNTRY_CODES,
  FeatureFlags,
  getAgeInYears,
  GLOBAL_BIOLOGICAL_GENDER_OPTIONS,
  InstanceKeys,
  isRealDate,
  Languages,
  Patient,
  surveyIds,
} from '@chiroup/core';
import { useForm } from '@chiroup/hooks';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ToastContext,
  ToastTypes,
} from '../../.../../../contexts/toast.context';
import { MeContext } from '../../../contexts/me.context';
import patientService from '../../../services/patient.service';
import userService from '../../../services/user.service';
import Button, { ButtonColors } from '../../common/Button';
import ClinicianSelect from '../../common/fields/selects/ClinicianSelect';
import HealthcareProviderSelect from '../../common/fields/selects/HealthcareProviderSelect';
import LanguageSelect from '../../common/fields/selects/LanguageSelect';
import LocationSelect from '../../common/fields/selects/LocationSelect';
import Modal from '../../common/Modal';
import { createPatientValidators } from '../../common/validators/patientValidators';
import ValidEmailAlert from '../../common/ValidEmailAlert';
import { transformPreferences } from '../../settings/clinic/ClinicSettingsPatientPreferences';
import { PatientPreferencesForm } from '../../settings/clinic/ClinicSettingsPatientPreferencesForm';
import PatientData from '../detail/PatientData';
import PatientMergeConfirmationModal from '../detail/PatientMergeConfirmationModal';
import PatientExistsModal from '../existing/PatientExistsModal';
import usePatientsSearch from '../hooks/usePatientsSearch';
import { CreatePatient } from '../types/CreatePatient.type';
import PatientSurveySelect from './PatientSurveySelect';
import useDatabase from '../../settings/database/useDatabase';

// const patientPreferencesCommTypeTranslations: {
//   [key: string]: CommunicationMethodTypes;
// } = {
//   prefersText: CommunicationMethodTypes.text,
//   prefersEmail: CommunicationMethodTypes.email,
//   prefersNone: CommunicationMethodTypes.none,
// };

const emptyPatient: Partial<Patient> = {
  fname: '',
  lname: '',
  email: '',
  language: Languages.en,
  guardian: false,
  // prefers: CommunicationMethodTypes.text,
};

//before creating patient we can possibly check email validity.
const createPatient = async (clinicId = -1, val: CreatePatient) => {
  return await patientService.create(val, clinicId);
};

type Props = {
  isOpen: boolean;
  close: (val?: Patient) => void;
  lastName?: string;
  closeSearch?: () => void;
  stayOnRoute?: boolean;
  afterCreate?: (patientId: string) => void;
};

const PatientCreateModal: React.FC<Props> = ({
  isOpen,
  close,
  lastName,
  closeSearch,
  stayOnRoute = false,
  afterCreate,
}) => {
  const { createToast } = useContext(ToastContext);
  const { me, hasAccess } = useContext(MeContext);
  const primaryClinicianDefault = me.selectedClinic?.clinicians?.find(
    (clinician) => clinician.ID === me.ID,
  )
    ? me.ID
    : me.selectedClinic?.clinicians?.length === 1
      ? me.selectedClinic?.clinicians?.[0]?.ID
      : '';
  const navigate = useNavigate();
  const {
    value,
    registerSubmit,
    isDirty,
    errors,
    onChange,
    isSubmitting,
    patchValue,
    reset,
    setIsSubmitting,
  } = useForm<CreatePatient>(
    { ...emptyPatient, primaryClinician: primaryClinicianDefault },
    createPatientValidators,
  );
  const { addRecentPatient } = usePatientsSearch({ disableList: true });
  const [patientExistsModalState, setPatientExistsModalState] = useState(
    OpenClosedStates.Closed,
  );
  const [patientMergeConfirmationModal, setPatientMergeConfirmationModal] =
    useState(OpenClosedStates.Closed);
  const [existingPatients, setExistingPatients] = useState<any[]>();
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [existsIsBusy, setExistsIsBusy] = useState(false);
  const [confirmNewPatient, setConfirmNewPatient] = useState<any>(null);
  const [notValidEmail, setNotValidEmail] = useState<boolean>(false);
  const [emailJustChecked, setEmailJustChecked] = useState<string>('');
  const emailInvalid = useRef<boolean>(false);
  const { data: caseTypes } = useDatabase({
    instanceKey: InstanceKeys.caseTypes,
    allCaseTypes: true,
  });

  const patientPreferencesTranslations: { [key: string]: string } =
    useMemo(() => {
      return {
        allergySurvey: surveyIds.allergy,
        'Back Bournemouth Questionnaire': surveyIds.backBournemouth,
        lefs: surveyIds.lefs,
        'Neck Bournemouth Questionnaire': surveyIds.neckBournemouth,
        ndi: surveyIds.ndi,
        quickDash: surveyIds.dash,
        oswestry: surveyIds.oswestry,
        ccs: surveyIds.ccs,
        familyHistory: surveyIds.familyHistory,
        healthHistory: surveyIds.healthHistory,
        lifestyleHistory: surveyIds.lifestyleHistory,
        medicationHistory: surveyIds.medicationHistory,
        reviewOfSystems: surveyIds.reviewOfSystems,
        surgicalHistory: surveyIds.surgicalHistory,
        photoID: 'requestPhotoId',
        insurance: hasAccess(FeatureFlags.billingInsurance)
          ? 'requestInsurance'
          : 'requestInsuranceCard',
        newInsurance: 'requestInsurance',
        demographics: 'requestInfo',
      };
    }, [hasAccess]);

  const toggleAdditionalInfo = () => {
    setShowAdditionalInfo((prev) => !prev);
  };

  const closeExistsModal = () => {
    setPatientExistsModalState(OpenClosedStates.Closed);
  };

  const closeMergeConfirmModal = () => {
    setPatientMergeConfirmationModal(OpenClosedStates.Closed);
  };

  useEffect(() => {
    if (me?.selectedClinic?.settings && !hasAccess(FeatureFlags.ehr)) {
      const transformedSettings = Array.isArray(me.selectedClinic?.settings)
        ? transformPreferences(me.selectedClinic?.settings)
        : me.selectedClinic?.settings || {};
      const newPatientVals = Object.keys(patientPreferencesTranslations).reduce(
        (
          obj: {
            ccsFollowups?: string[];
            surveys?: string[];
            prefers?: CommunicationMethodTypes;
            requestInfo?: boolean;
            requestInsuranceCard?: boolean;
            requestInsurance?: boolean;
            requestPhotoId?: boolean;
          },
          setting: any,
        ) => {
          if (
            ['demographics', 'photoID', 'insurance'].includes(setting) &&
            transformedSettings[setting as keyof PatientPreferencesForm]
          ) {
            obj.surveys?.push(patientPreferencesTranslations[setting]);
          } else if (
            transformedSettings[setting as keyof PatientPreferencesForm]
          ) {
            const translation = patientPreferencesTranslations[setting];
            if (
              setting === 'ndi' ||
              setting === 'lefs' ||
              setting === 'quickDash' ||
              setting === 'oswestry'
            ) {
              obj.ccsFollowups?.push(translation);
            } else if (
              (translation && !hasAccess(FeatureFlags.ehr)) ||
              (translation &&
                translation !== surveyIds.ccs &&
                hasAccess(FeatureFlags.ehr))
            ) {
              obj.surveys?.push(translation);
            }
          }
          return obj;
        },
        { ccsFollowups: [], surveys: [] },
      );

      patchValue(newPatientVals);
    }
  }, [
    hasAccess,
    isOpen,
    me.selectedClinic?.settings,
    patchValue,
    patientPreferencesTranslations,
  ]);

  useEffect(() => {
    if (lastName) {
      const capLastName =
        lastName.substring(0, 1).toUpperCase() + lastName.slice(1);
      patchValue({ lname: capLastName });
    }
  }, [lastName, patchValue]);

  const onSuccess = (data: CreatePatient, skipExistingCheck?: boolean) => {
    if (!skipExistingCheck && existingPatients?.length) {
      return;
    }
    if (emailInvalid.current) return;
    setExistingPatients(undefined);
    addRecentPatient(data as Patient);
    closeModal(data as Patient);
    closeSearch?.();
    if (data.ID && !stayOnRoute) {
      if (afterCreate) {
        afterCreate(data.ID);
      } else {
        navigate(`/patients/${data.ID}`);
      }
    }
  };

  const onFail = (err: any) => {
    if (err?.message === 'invalid_email') return;
    createToast({
      type: ToastTypes.Fail,
      title: 'Failed to create',
      description: err.message,
    });
  };

  const createAnyway = async () => {
    try {
      setExistsIsBusy(true);
      const res = await submit(value, true);
      closeExistsModal();
      onSuccess(res, true);
      setExistsIsBusy(false);
    } catch (err) {
      onFail(err);
      setExistsIsBusy(false);
    }
  };

  const mergePatients = async (ids: string[]) => {
    setExistsIsBusy(true);
    try {
      const newPatient = await patientService.merge(
        me?.selectedClinic?.ID || -1,
        ids,
        false,
        value,
      );
      setExistsIsBusy(false);
      setConfirmNewPatient({ ids, patient: newPatient });
    } catch (err) {
      setConfirmNewPatient(null);
      setExistsIsBusy(false);
      createToast({
        type: ToastTypes.Fail,
        title: 'Failed to merge',
        description: <>Failed to create and merge patients.</>,
      });
    }
  };

  const mergePatientsConfirm = async () => {
    setExistsIsBusy(true);
    try {
      const newPatient = await patientService.merge(
        me?.selectedClinic?.ID || -1,
        confirmNewPatient?.ids,
        true,
        value,
      );
      setExistsIsBusy(false);
      closeExistsModal();
      close();
      closeModal();
      closeSearch?.();
      setConfirmNewPatient(null);
      if (newPatient.ID && !stayOnRoute) {
        if (afterCreate) {
          afterCreate(newPatient.ID);
        } else {
          navigate(`/patients/${newPatient.ID}`);
        }
      }
    } catch (err) {
      setExistsIsBusy(false);
      createToast({
        type: ToastTypes.Fail,
        title: 'Failed to merge',
        description: <>Failed to create and merge patients.</>,
      });
    }
  };

  const submit = async (values: CreatePatient, skipExistingCheck?: boolean) => {
    setNotValidEmail(false);
    emailInvalid.current = false;
    if (
      values.surveys?.length &&
      !values.email &&
      !values.phone &&
      values.method !== CommunicationMethodTypes.kiosk
    ) {
      throw new Error('Email or phone required if survey is selected.');
    }

    if (emailJustChecked !== values.email && values.email !== '') {
      const zbResult = await userService.checkIfValidEmail(values.email || '');
      setEmailJustChecked(values.email || '');
      if (zbResult?.data?.status?.toLowerCase() === 'invalid') {
        emailInvalid.current = true;
        setNotValidEmail(true);
        throw new Error('invalid_email');
      }
    }

    if (!skipExistingCheck) {
      // make a call to backend to check if patient exists, if so open modal
      const existing: any = await patientService.exists(
        {
          fname: values.fname,
          lname: values.lname,
          email: values.email,
          phone: values.phone,
          dateOfBirth: values.dateOfBirth,
        },
        me.selectedClinic?.ID,
      );

      if (existing?.data?.length) {
        setPatientExistsModalState(OpenClosedStates.Open);
        setIsSubmitting(false);

        setExistingPatients(existing.data);

        return { skipSuccess: true };
      }
    }

    const val = values;

    val.prefers = val.phone
      ? CommunicationMethodTypes.text
      : CommunicationMethodTypes.none;

    if (!val.phone && val.email) {
      val.prefers = CommunicationMethodTypes.email;
    }

    let surveysToSend = [...(val.surveys || [])];
    //request Insurance is ehr and requestInsuranceCard is essential.
    const specialRequests = {
      requestInsuranceCard: false,
      requestInsurance: false,
      requestInfo: false,
      requestPhotoId: false,
    };
    if (surveysToSend?.includes('requestInfo')) {
      specialRequests.requestInfo = true;
    }
    if (surveysToSend?.includes('requestInsuranceCard')) {
      specialRequests.requestInsuranceCard = true;
    }
    if (surveysToSend?.includes('requestPhotoId')) {
      specialRequests.requestPhotoId = true;
    }
    if (surveysToSend?.includes('requestInsurance')) {
      specialRequests.requestInsurance = true;
    }
    surveysToSend = surveysToSend?.filter((survey, i) => {
      switch (survey) {
        case 'requestInfo':
          return false;
        case 'requestInsuranceCard':
          return false;
        case 'requestPhotoId':
          return false;
        case 'requestInsurance':
          return false;
        case 'basicIntakeGroup':
          return false;
        case 'detailedIntakeGroup':
          return false;
        default:
          return (
            !survey.startsWith('consent::') &&
            surveysToSend.indexOf(survey) === i
          );
      }
    });
    const consents = val.surveys
      ?.filter((survey) => survey.startsWith('consent::'))
      .map((survey) => survey.replace('consent::', ''));
    const { ccsFollowups, method, ...patient } = val;
    const language = me.selectedClinic?.language as Languages;

    return await createPatient(me.selectedClinic?.ID, {
      ...specialRequests,
      surveys: surveysToSend,
      consents,
      method,
      ccsFollowups,
      patient: {
        ...patient,
        language: value.language || Languages[language === 'es' ? 'es' : 'en'],
      },
    });
  };

  const closeModal = (val?: Patient) => {
    setNotValidEmail(false);
    reset();
    close(val);
    closeExistsModal();
  };

  useEffect(() => {
    patchValue({ method: value.prefers });
  }, [value.prefers, patchValue]);

  const destinationOptions = useMemo(() => {
    const options = [
      {
        value: CommunicationMethodTypes.email,
        text: 'Email',
      },
      {
        value: CommunicationMethodTypes.text,
        text: 'Text message',
      },
      {
        value: CommunicationMethodTypes.kiosk,
        text: 'Kiosk',
      },
    ];
    return options;
  }, []);

  const ageInYears = useMemo(() => {
    if (!value.dateOfBirth) {
      return null;
    }
    return getAgeInYears(value.dateOfBirth);
  }, [value.dateOfBirth]);

  const handleAgeChange = (e: string) => {
    patchValue({
      dateOfBirth: e,
    });
    if (isRealDate(e || '')) {
      const age = getAgeInYears(e || '');
      if (age < 18) {
        patchValue({
          guardian: true,
        });
        setShowAdditionalInfo(true);
      }
    }
  };

  const customIntakes = useMemo(() => {
    const customSettings = me.selectedClinic?.settings?.find(
      (setting) => setting.setting === 'Custom intakes',
    );
    return {
      basicIntakeGroup: customSettings?.jsonValue?.basicIntakeSurveys,
      detailedIntakeGroup: customSettings?.jsonValue?.detailedIntakeSurveys,
    };
  }, [me.selectedClinic?.settings]);

  return (
    <>
      <Modal isOpen={isOpen} close={closeModal} disableClickOutsideClose>
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 dark:bg-darkGray-600">
          <svg
            className="h-6 w-6 text-gray-600 dark:text-darkGray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
          </svg>
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3
            className="text-lg leading-6 font-medium text-gray-900 dark:text-darkGray-100"
            id="modal-headline"
          >
            Add patient
          </h3>
          <div className="mt-2">
            <div className="mt-4 text-left grid grid-cols-6 gap-y-2 sm:gap-y-0 gap-x-4">
              <Input
                name="fname"
                className="fname col-span-6 sm:col-span-3"
                label="First name *"
                value={value.fname}
                onChange={onChange('fname')}
                errors={errors.fieldErrors?.fname}
              />
              <Input
                name="lname"
                className="lname col-span-6 sm:col-span-3"
                label="Last name *"
                value={value.lname}
                onChange={onChange('lname')}
                errors={errors.fieldErrors?.lname}
              />
              <Input
                name="pname"
                className="fname col-span-6 sm:col-span-3"
                label="Preferred name"
                value={value.pname}
                onChange={onChange('pname')}
                errors={errors.fieldErrors?.pname}
              />
              <InputMasked
                className="col-span-3"
                name="dateOfBirth"
                label="Date of birth"
                value={value.dateOfBirth}
                onChange={onChange('dateOfBirth')}
                errors={errors.fieldErrors?.dateOfBirth}
                patternFormat="##/##/####"
                placeholder="MM/DD/YYYY"
                onBlur={handleAgeChange}
              />
              <Phone
                name="phone"
                className="phone col-span-6 sm:col-span-3"
                label={
                  value.guardian
                    ? `Legal guardian mobile number`
                    : `Mobile number`
                }
                value={value.phone}
                onChange={onChange('phone')}
                errors={errors.fieldErrors?.phone}
                countryCode={COUNTRY_CODES[me.selectedClinic?.country || 'USA']}
              />
              <Input
                name="email"
                className="email col-span-6 sm:col-span-3"
                label={value.guardian ? `Legal guardian email` : `Email`}
                value={value.email}
                onChange={onChange('email')}
                errors={errors.fieldErrors?.email}
              />
              {notValidEmail && <ValidEmailAlert styles={'mt-2 col-span-6'} />}
              <ClinicianSelect
                className="clinician col-span-6 sm:col-span-3"
                name="primaryClinician"
                value={value.primaryClinician}
                onChange={onChange('primaryClinician')}
                errors={errors.fieldErrors?.primaryClinician}
                label="Primary clinician *"
                limit={1}
              />
              <LocationSelect
                className="col-span-6 sm:col-span-3"
                name="location"
                value={value.location}
                onChange={onChange('location')}
                errors={errors.fieldErrors?.location}
                label="Default location *"
                limit={1}
              />
              {/* <Select
                name="prefers"
                className="prefers col-span-6 sm:col-span-3"
                label="Prefers"
                value={value.prefers}
                onChange={onChange('prefers')}
                errors={errors.fieldErrors?.prefers}
                options={PREFERS}
                limit={1}
	/> */}
              <span
                onClick={toggleAdditionalInfo}
                className="text-primary-600 hover:text-primary-400 flex justify-end text-sm cursor-pointer col-span-6 mt-2"
              >
                {showAdditionalInfo ? (
                  <>Less detail &uarr;</>
                ) : (
                  <>More detail &darr;</>
                )}
              </span>
              {showAdditionalInfo && (
                <>
                  <Toggle
                    label="Requires Guardianship"
                    onChange={(val) => {
                      if (!val) {
                        patchValue({
                          legalGuardianName: '',
                          legalGuardianRelationship: '',
                        });
                      }
                      patchValue({
                        guardian: val,
                      });
                    }}
                    value={value.guardian || false}
                    className="col-span-6 pt-3"
                    disabled={!!(ageInYears && ageInYears < 18)}
                  />
                  <Select
                    name="gender"
                    className="col-span-3"
                    label="Sex"
                    value={value.gender}
                    onChange={onChange('gender')}
                    errors={errors.fieldErrors?.gender}
                    options={GLOBAL_BIOLOGICAL_GENDER_OPTIONS}
                    limit={1}
                  />
                  <LanguageSelect
                    className="col-span-3"
                    label="Primary Language *"
                    value={value.language}
                    onChange={onChange('language')}
                    errors={errors.fieldErrors?.language}
                  />
                  <HealthcareProviderSelect
                    label="Primary healthcare provider"
                    className="col-span-6"
                    name="primaryProvider"
                    value={value.primaryProvider}
                    onChange={onChange('primaryProvider')}
                    errors={errors.fieldErrors?.primaryProvider}
                  />
                  {value.guardian && (
                    <LegalGuardianFields
                      fieldErrors={errors.fieldErrors}
                      onChange={onChange}
                      values={value}
                    />
                  )}
                </>
              )}
            </div>
            {isOpen &&
              !me?.selectedClinic?.noPatientCommunication &&
              !hasAccess('ehr') && (
                <div className="my-4 col-span-6 text-left">
                  <PatientSurveySelect
                    value={value.surveys}
                    onChange={(val: string[]) => {
                      if (val?.includes('basicIntakeGroup')) {
                        if (customIntakes?.basicIntakeGroup) {
                          val = val.concat(customIntakes.basicIntakeGroup);
                        } else {
                          if (!hasAccess(FeatureFlags.ehrNotes)) {
                            val = val.concat([surveyIds.ccs]);
                          }
                          val = val.concat([
                            surveyIds.healthHistory,
                            surveyIds.reviewOfSystems,
                          ]);
                        }
                      }

                      if (val?.includes('detailedIntakeGroup')) {
                        if (customIntakes?.detailedIntakeGroup) {
                          val = val.concat(customIntakes.detailedIntakeGroup);
                        } else {
                          if (!hasAccess(FeatureFlags.ehrNotes)) {
                            val = val.concat([surveyIds.ccs]);
                          }
                          val = val.concat([
                            surveyIds.healthHistory,
                            surveyIds.reviewOfSystems,
                            surveyIds.lifestyleHistory,
                            surveyIds.familyHistory,
                            surveyIds.medicationHistory,
                            surveyIds.surgicalHistory,
                            'requestInfo',
                          ]);
                        }
                      }

                      val = val.filter(
                        (surveyId) =>
                          surveyId !== 'basicIntakeGroup' &&
                          surveyId !== 'detailedIntakeGroup',
                      );

                      onChange('surveys')(val);
                    }}
                    errors={errors.fieldErrors?.surveys}
                  />
                  <RadioGroup
                    name="method"
                    className="col-span-6"
                    label="Destination"
                    value={value.method}
                    onChange={onChange('method')}
                    errors={errors.fieldErrors?.method}
                    options={destinationOptions}
                  />
                </div>
              )}
          </div>
        </div>
        <div className={'mt-6 grid gap-3 grid-flow-row-dense grid-cols-2'}>
          <Button
            text="Close"
            onClick={() => {
              closeModal();
            }}
            fullWidth
            color={ButtonColors.plain}
            className="border border-gray-300 dark:border-darkGray-600"
          />
          <Button
            text="Create"
            onClick={registerSubmit(submit, {
              onSuccess,
              onFail,
            })}
            fullWidth
            loading={isSubmitting}
            disabled={!isDirty}
          />
        </div>
      </Modal>
      <PatientExistsModal
        isOpen={patientExistsModalState === OpenClosedStates.Open}
        close={closeModal}
        closeSearch={closeSearch}
        existing={existingPatients}
        createAnyway={createAnyway}
        mergePatients={mergePatients}
        existsIsBusy={existsIsBusy}
      />
      <PatientMergeConfirmationModal
        isOpen={patientMergeConfirmationModal === OpenClosedStates.Open}
        close={closeMergeConfirmModal}
        existing={existingPatients}
      />
      <Modal
        isOpen={!!confirmNewPatient}
        close={() => setConfirmNewPatient(null)}
      >
        <div className="w-full">
          <div className="flex items-end justify-center w-full text-center sm:block sm:p-0">
            <div>
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 dark:bg-darkGray-600">
                <svg
                  className="h-6 w-6 text-gray-600 dark:text-darkGray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900 dark:text-darkGray-100"
                  id="modal-headline"
                >
                  New patient preview
                </h3>
                <div className="text-left mt-12">
                  <PatientData
                    patient={confirmNewPatient?.patient}
                    caseTypes={caseTypes as Partial<ClinicCaseType>[]}
                  />
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <span className="flex w-full gap-x-4">
                <Button
                  text="Cancel"
                  onClick={() => setConfirmNewPatient(null)}
                  fullWidth
                  color={ButtonColors.plain}
                  className="border border-gray-300 dark:border-darkGray-600"
                />
                <Button
                  text="Confirm merge"
                  onClick={mergePatientsConfirm}
                  fullWidth
                  loading={existsIsBusy}
                  disabled={existsIsBusy}
                />
              </span>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PatientCreateModal;
