import { Currency, TrivialTooltip } from '@chiroup/components';
import {
  AppointmentInsuranceType,
  PatientTransaction,
  PatientTransactionItemType,
  STRING_NUMBER_HASH,
  TransactionItemSubtypeEnum,
  calculateResponsibilities,
  createDecimal,
  subtotalAndTaxFromItems,
} from '@chiroup/core';
import { useContext, useMemo } from 'react';
import { MeContext } from '../../../../../../contexts/me.context';
import useProductTaxes from '../../../../../../hooks/useProductTaxes';
import Decimal from 'decimal.js';

type Props = {
  treatmentName?: string;
  insurances?: Partial<AppointmentInsuranceType>[];
  courtesyBilling?: boolean;
  superBill?: boolean;
  items?: PatientTransactionItemType[];
  editing?: boolean;
  setWorkingTransaction?: React.Dispatch<
    React.SetStateAction<PatientTransaction | null>
  >;
  workingTransaction?: PatientTransaction;
  locationId: number;
  disciplineId: number;
};
const EncounterBillingView: React.FC<Props> = ({
  treatmentName = '',
  insurances = [],
  courtesyBilling,
  superBill,
  items = [],
  editing,
  setWorkingTransaction,
  workingTransaction,
  locationId,
  disciplineId,
}) => {
  const { me } = useContext(MeContext);
  const { data: canItBeTaxed } = useProductTaxes();

  const services = items?.filter(
    (item) => item.subtype === TransactionItemSubtypeEnum.Service,
  );

  const patientServices = items?.filter(
    (item) => item.subtype === TransactionItemSubtypeEnum.PatientService,
  );

  const savedTax = items?.find(
    (item) => item.subtype === TransactionItemSubtypeEnum.Tax,
  )?.amount;

  const hasService = !!services?.length || !!patientServices?.length;

  const itemsToUse = useMemo(() => {
    if (!hasService) {
      return items;
    }
    return items?.filter(
      (item) =>
        item.subtype === TransactionItemSubtypeEnum.Service ||
        item.subtype === TransactionItemSubtypeEnum.PatientService,
    );
  }, [items, hasService]);

  const { subtotal, tax, total, balance } = useMemo(() => {
    const locationToUse = me?.selectedClinic?.locations?.find(
      (loc) => loc.ID === locationId,
    );
    const productTaxRate = locationToUse?.productTaxRate || 0;
    const serviceTaxRate = locationToUse?.serviceTaxRate || 0;
    return subtotalAndTaxFromItems({
      items:
        (hasService && items.length
          ? items.filter(
              (item) => item.subtype !== TransactionItemSubtypeEnum.Treatment,
            )
          : items) || [],
      productTaxRate: productTaxRate,
      serviceTaxRate: serviceTaxRate,
      makeRateZero: !itemsToUse?.some((item) => item?.salesTax === true),
    });
  }, [
    me?.selectedClinic?.locations,
    hasService,
    items,
    itemsToUse,
    locationId,
  ]);

  const applyTax = itemsToUse?.some((item) => item?.salesTax) === true;

  const showTaxButton =
    editing &&
    itemsToUse?.some(
      (item) =>
        (item.subtype === TransactionItemSubtypeEnum.Supplement &&
          item.description &&
          canItBeTaxed?.supplementsItemTaxHash[item?.description]) ||
        (item?.subtype === TransactionItemSubtypeEnum.Treatment &&
          item.description &&
          canItBeTaxed?.treatmentsItemTaxHash[disciplineId]),
    );

  const figureOutIfWeCanChangeSalesTax = (
    subtype: TransactionItemSubtypeEnum | null,
    item: PatientTransactionItemType,
  ) => {
    if (subtype === TransactionItemSubtypeEnum.Supplement) {
      return item?.description &&
        canItBeTaxed?.supplementsItemTaxHash[item?.description]
        ? !item?.salesTax
        : false;
    }
    if (subtype === TransactionItemSubtypeEnum.Treatment) {
      return disciplineId && canItBeTaxed?.treatmentsItemTaxHash[disciplineId]
        ? !item?.salesTax
        : false;
    }
    return false;
  };

  const changeSalesTax = () => {
    if (!setWorkingTransaction) return;
    setWorkingTransaction((prev) => {
      if (prev === null) return null;
      //itemtaxhash lets us know if the item can be taxed or not
      return {
        ...prev,
        items: prev?.items?.map((item) => {
          return {
            ...item,
            salesTax: figureOutIfWeCanChangeSalesTax(item.subtype, item),
          };
        }),
      };
    });
  };

  const insurancesToUse = useMemo(() => {
    return insurances
      .sort(
        (a, b) => (a.billingPriority as number) - (b.billingPriority as number),
      )
      .map((insurance) => {
        const {
          payorID,
          insuranceName,
          billingPriority,
          deductible,
          coPay,
          coInsurance,
          serviceAllowedAmounts,
        } = insurance;
        const trivialAllowedAmount = Object.keys(
          serviceAllowedAmounts || {},
        ).reduce((acc, cur) => {
          if (!serviceAllowedAmounts) return acc;
          return acc + Number(serviceAllowedAmounts[cur].allowedAmount);
        }, 0);
        const compactServiceAmounts = Object.keys(
          serviceAllowedAmounts ?? {},
        ).reduce((acc, cur) => {
          if (!serviceAllowedAmounts) return acc;
          acc[cur] = Number(serviceAllowedAmounts[cur].allowedAmount);
          return acc;
        }, {} as STRING_NUMBER_HASH);
        return {
          payorId: payorID as number,
          payorName: insuranceName as string,
          billingPriority: billingPriority as number,
          deductible: Number(deductible) || 0,
          coPay: Number(coPay) || 0,
          coInsurance: Number(coInsurance) || 0,
          allowedAmount: trivialAllowedAmount,
          serviceAllowedAmounts: compactServiceAmounts,
          maxPerVisit: Number(insurance.maxPerVisit),
        };
      });
  }, [insurances]);

  const responsibilities = useMemo(() => {
    if (!services?.length) {
      return { payorResponsibilities: {}, finalPatientResponsibility: 0 };
    }
    return calculateResponsibilities({
      insurances: insurancesToUse as any,
      items,
      courtesyBilling,
      superBill,
      allocateToPatient: workingTransaction?.allocateToPatient,
      allocationAmount: workingTransaction?.allocatedFromClaimsAmount,
    });
  }, [
    services?.length,
    insurancesToUse,
    items,
    courtesyBilling,
    superBill,
    workingTransaction?.allocateToPatient,
    workingTransaction?.allocatedFromClaimsAmount,
  ]);

  const payorResponsibilityTotal = Object.values(
    responsibilities.payorResponsibilities,
  )?.reduce((acc, cur) => acc + Number(cur.amount), 0);

  const maxPerVisitByPayorId = useMemo(() => {
    return insurancesToUse?.reduce((acc, cur) => {
      acc[cur.payorId] = cur.maxPerVisit;
      return acc;
    }, {} as STRING_NUMBER_HASH);
  }, [insurancesToUse]);

  const balanceAllocatedToPatient = useMemo(() => {
    return (workingTransaction?.items ?? [])
      .filter(
        (i) =>
          i.subtype === TransactionItemSubtypeEnum.BalanceAllocatedToPatient,
      )
      .reduce((acc: Decimal, item) => {
        return acc.plus(Number(item.amount) || 0);
      }, createDecimal(0));
  }, [workingTransaction?.items]);

  return (
    <div className="border overflow-hidden rounded-md border-gray-300 border-spacing-4 p-4">
      {!!insurancesToUse?.length && !!services.length && (
        <div className="p-4 bg-gray-100 dark:bg-darkGray-500 rounded-md">
          {Object.entries(responsibilities.payorResponsibilities ?? {})?.map(
            ([key, value]) => (
              <div key={key} className="flex justify-between mb-2">
                <span>
                  {value.name}
                  {!!maxPerVisitByPayorId[value.payorId] && (
                    <TrivialTooltip
                      iconClassName="h-3 w-3 text-gray-400 inline-block ml-1 mr-1 align-text-top"
                      text={`This payor allows a maximum charge of $${createDecimal(
                        maxPerVisitByPayorId[value.payorId] ?? 0,
                      ).toFixed(
                        2,
                      )}, encompassing both patient and payor contributions.`}
                    />
                  )}
                  :
                </span>
                <Currency value={value.amount} />
              </div>
            ),
          )}
          <div className="flex justify-between mb-2 dark:bg-darkGray-500">
            <span>Patient Responsibility:</span>
            <Currency
              value={createDecimal(responsibilities.finalPatientResponsibility)
                .plus(balanceAllocatedToPatient)
                .toFixed(2)}
            />
          </div>
          <div className="flex justify-between mb-2 dark:bg-darkGray-500">
            <span>Contractual Adjustment:</span>
            <Currency
              value={createDecimal(total)
                .minus(responsibilities.finalPatientResponsibility)
                .minus(balanceAllocatedToPatient.plus(payorResponsibilityTotal))
                .toFixed(2)}
              negativeClassName="text-red-500"
            />
          </div>
        </div>
      )}
      <div className="pb-4 font-bold">{treatmentName ?? ''}</div>
      <div className="flex justify-between mb-2">
        <span>Subtotal:</span>
        <Currency value={subtotal} />
      </div>
      <div className="flex justify-between mb-2">
        <span>Tax:</span>

        {balance > 0 && changeSalesTax && showTaxButton && (
          <div className="ml-4 sm:ml-64">
            <span
              onClick={() => changeSalesTax()}
              className="cursor-pointer text-primary-500 hover:text-primary-400 text-xs mr-8"
            >
              {applyTax ? 'Remove Tax' : 'Apply Tax'}
            </span>{' '}
          </div>
        )}
        <Currency value={editing ? tax : savedTax} />
      </div>
      <div className="flex justify-between mb-2">
        <span>Total:</span>
        <Currency value={total} />
      </div>
      {!!balance && (
        <div className="flex justify-between mb-2">
          <span>Balance:</span>
          <Currency value={balance} />
        </div>
      )}
    </div>
  );
};

export default EncounterBillingView;
