import {
  Availability,
  ClinicLocation,
  Discipline,
  DisciplineTreatment,
  functionalDisabilitySurveys,
  isExtendedClinicSetting,
  Kiosk,
  MeClinic,
  Overrides,
  User,
} from '@chiroup/core';
import { ChiroUpAPI } from '@chiroup/client-core';
import dayjs from 'dayjs';
import { CreatePatient } from '../components/patients/types/CreatePatient.type';
import { ClinicGeneralSettings } from '../components/settings/clinic/ClinicSettingsGeneral';
import { NewUserForm } from '../components/settings/clinic/ClinicSettingsUsersAddUserModal';

import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const clinicService = () => {
  /**
   * For saving, the settings that are in the ClinicExtendedSettings
   * and Clinic table are broken out into their own objects. The latter
   * does not seem to care if there are unknown properties, but the
   * former will certainly save more than it should if it gets the
   * entire payload.
   *
   * Note that the extended settings are not merged with what is
   * currently saved in the jsonValue. It is replaced, so the
   * payload that comes in needs to be complete.
   */
  const save = (
    clinic: any & { ID: number },
    opts: { userId: string; setting: string; section: string },
  ): Promise<ClinicGeneralSettings> => {
    /**
     * I had some strict-typing issues with these objects, so
     * had to go with any.
     */
    const extendedPayload: { [key: string]: any } = {};
    const normalPayload: { [key: string]: any } = {};
    let countofExtendedSettings = 0;

    Object.keys(clinic).forEach((key) => {
      if (isExtendedClinicSetting(key)) {
        extendedPayload[key] = clinic[key];
        countofExtendedSettings++;
      } else {
        normalPayload[key] = clinic[key];
      }
    });

    /**
     * Might be able to get away without a new promise, but I
     * like the idea of the explicit resolve/reject, seems
     * more clear. Well, as clear as promises can be.
     */
    return new Promise((resolve, reject) => {
      ChiroUpAPI.put('api', `/settings/clinics/${clinic.ID}`, {
        body: normalPayload,
      }).then(
        (primarySaveResponse) => {
          if (countofExtendedSettings === 0) {
            resolve(primarySaveResponse);
          } else {
            saveExtendedClinicSettings({
              clinicId: clinic.ID,
              userId: opts.userId,
              opts: { setting: opts.setting, section: opts.section },
              data: { ...extendedPayload },
            }).then(
              () => {
                resolve(primarySaveResponse);
              },
              (err) => {
                reject(err);
              },
            );
          }
        },
        (err) => {
          reject(err);
        },
      );
    });
  };

  const create = async (
    clinic: ClinicGeneralSettings,
    patient?: Partial<CreatePatient>,
  ): Promise<MeClinic> => {
    // TODO: Make one request to create the clinic and a patient
    const clinicRes = await ChiroUpAPI.post('api', `/clinic`, {
      body: clinic,
    });
    await ChiroUpAPI.post('api', `/patients/${clinicRes.ID}/patients`, {
      body: {
        patient: { ...patient, clinic: clinicRes.ID, prefers: 'email' },
      },
    });
    return clinicRes;
  };

  const getUsers = async (clinicId = 0, userId = '') => {
    const res = await ChiroUpAPI.get(
      'api',
      `/users/${clinicId}/user?skip=0&limit=9999`,
      {},
    );
    return res.map((user: User) => ({
      ...user,
      enableOnlineBookings: !!user.enableOnlineBookings,
    }));
  };

  const saveUser = async (clinicId: number, userId: string, val: any) => {
    return ChiroUpAPI.put('api', `/settings/${clinicId}/user/${userId}`, {
      body: val,
    });
  };

  const savePatientPreferences = async (
    clinicId: number,
    userId: string,
    val: any,
  ) => {
    const sendVal = transformPreferencesForServer(val);
    const res = await ChiroUpAPI.put(
      'api',
      `/settings/clinics/${clinicId}/extended`,
      {
        body: sendVal,
      },
    );
    return res;
  };

  const saveExtendedClinicSettings = async (params: {
    clinicId: number;
    userId: string;
    opts: { setting: string; section: string };
    data: any;
  }) => {
    const sendVal = formValuesToPayload(
      { name: params.opts.setting, type: params.opts.section },
      params.data,
    );
    const res = await ChiroUpAPI.put(
      'api',
      `/settings/clinics/${params.clinicId}/extended`,
      {
        body: sendVal,
      },
    );
    return res;
  };

  const removeUser = async (clinicId = 0, userToRemove: string) => {
    return ChiroUpAPI.del(
      'api',
      `/settings/${clinicId}/user/${userToRemove}`,
      {},
    );
  };

  const findUserByEmail = async (clinicId = 0, userId = '', email: string) => {
    return ChiroUpAPI.get('api', `/users/user/find`, {
      queryParams: {
        email,
      },
    });
  };

  const addUser = async (
    clinicId = 0,
    userId = '',
    val: NewUserForm & { ID: string | null },
  ) => {
    delete val.emailCheck;
    return ChiroUpAPI.post('api', `/users/${clinicId}/user`, {
      body: { ...val, add: true },
    });
  };

  const listIcons = async (clinicId = 0) => {
    const res = await ChiroUpAPI.get('api', `/${clinicId}/icons`, {});
    return res;
  };

  const retryIcons = async (
    clinicId = 0,
    userId: string,
    primary?: string,
    accent?: string,
  ) => {
    return ChiroUpAPI.post('api', `/${clinicId}/${userId}/icons/retry`, {
      body: { primary, accent },
    });
  };

  const addKiosk = async ({
    clinicId,
    pairCode,
    location,
    name,
  }: {
    clinicId: number;
    pairCode: string;
    location?: number;
    name?: string;
  }): Promise<Kiosk> => {
    const res = await ChiroUpAPI.post('api', `/${clinicId}/kiosk`, {
      body: {
        pairCode,
        location,
        name,
      },
    });
    return {
      ...res,
      createDate: dayjs(res.createDate).utc(true).local(),
    };
  };

  const getKiosks = async (clinicId = 0): Promise<Kiosk[]> => {
    const res = await ChiroUpAPI.get('api', `/${clinicId}/kiosk`, {
      body: {},
    });

    return res?.map((item: any) => ({
      ...item,
      createDate: dayjs(item.createDate).utc(true).local(),
    }));
  };

  const removeKiosk = async (clinicId = 0, id: number) => {
    return ChiroUpAPI.del('api', `/${clinicId}/kiosk/${id}`, {});
  };

  const saveLocation = async (
    clinicId = 0,
    location: Partial<ClinicLocation>,
  ): Promise<ClinicLocation> => {
    if (location.ID) {
      return ChiroUpAPI.put(
        'api',
        `/settings/${clinicId}/locations/${location.ID}`,
        {
          body: location,
        },
      );
    } else {
      return ChiroUpAPI.put('api', `/settings/${clinicId}/locations`, {
        body: location,
      });
    }
  };

  const removeLocation = async (clinicId = 0, id: number) => {
    return ChiroUpAPI.del('api', `/settings/${clinicId}/locations/${id}`, {});
  };

  const getDisciplines = async (clinicId = 0) => {
    return ChiroUpAPI.get('api', `/settings/${clinicId}/disciplines`, {});
  };

  const addDiscipline = async (
    clinicId = 0,
    discipline: Partial<Discipline>,
  ): Promise<Discipline> => {
    return ChiroUpAPI.post('api', `/settings/${clinicId}/discipline`, {
      body: discipline,
    });
  };

  const updateDiscipline = async (
    clinicId = 0,
    discipline: Partial<Discipline>,
  ): Promise<Discipline> => {
    return ChiroUpAPI.put(
      'api',
      `/settings/${clinicId}/discipline/${discipline.ID}`,
      {
        body: discipline,
      },
    );
  };

  const addTreatment = async (
    clinicId = 0,
    treatment: Partial<DisciplineTreatment>,
  ): Promise<DisciplineTreatment> => {
    return ChiroUpAPI.post('api', `/settings/${clinicId}/treatment`, {
      body: treatment,
    });
  };

  const getTreatment = async (
    clinicId = 0,
    treatmentId = 0,
  ): Promise<DisciplineTreatment> => {
    let resp = await ChiroUpAPI.get(
      'api',
      `/${clinicId}/treatment/${treatmentId}`,
      {},
    );

    if (resp && Array.isArray(resp)) {
      resp = resp[0];
    }

    return resp;
  };

  const updateTreatment = async (
    clinicId = 0,
    treatment: Partial<DisciplineTreatment>,
  ): Promise<DisciplineTreatment> => {
    return ChiroUpAPI.put(
      'api',
      `/settings/${clinicId}/treatment/${treatment.ID}`,
      {
        body: treatment,
      },
    );
  };

  const getUserSchedule = async (
    clinicId: number,
    userId: string,
  ): Promise<any> => {
    return ChiroUpAPI.get(
      'api',
      `/settings/${clinicId}/schedule/${userId}`,
      {},
    );
  };

  const updateUserSchedule = async (
    clinicId: number,
    userId: string,
    schedule: any,
  ): Promise<any> => {
    return ChiroUpAPI.put('api', `/settings/${clinicId}/schedule/${userId}`, {
      body: schedule,
    });
  };

  const getClinicLocationSchedule = async (
    clinicId: number,
    locationId: number,
  ): Promise<{
    schedule: Availability;
    scheduleOverrides: Overrides;
  }> => {
    return ChiroUpAPI.get(
      'api',
      `/settings/${clinicId}/locations/${locationId}/schedule`,
      {},
    );
  };

  const getCreditDevices = (clinicId: number): Promise<any[]> => {
    return ChiroUpAPI.get('api', `/transactions/${clinicId}/terminals`, {});
  };

  const addCreditDevice = (
    name: string,
    standAlone: boolean,
    clinicId: number,
  ): Promise<any> => {
    return ChiroUpAPI.post('api', `/transactions/${clinicId}/terminal`, {
      body: {
        standAlone,
        name,
      },
    });
  };

  const removeCreditDevice = (
    clinicId: number,
    deviceKey: string,
  ): Promise<any> => {
    return ChiroUpAPI.del(
      'api',
      `/transactions/${clinicId}/terminal/${deviceKey}`,
      {},
    );
  };

  const saveLocationAvailability = async ({
    clinicId,
    locationId,
    availability,
    overrides,
  }: {
    clinicId: number;
    locationId: number;
    availability: Partial<Availability>;
    overrides?: Overrides;
  }): Promise<any> => {
    console.log({
      clinicId,
      locationId,
      availability,
      overrides,
    });
    return ChiroUpAPI.put(
      'api',
      `/settings/${clinicId}/locations/${locationId}/schedule`,
      {
        body: { availability, overrides },
      },
    );
  };

  return {
    save,
    create,
    getUsers,
    saveUser,
    removeUser,
    findUserByEmail,
    addUser,
    savePatientPreferences,
    saveExtendedClinicSettings,
    // subscribe,
    listIcons,
    retryIcons,
    addKiosk,
    removeKiosk,
    getKiosks,
    saveLocation,
    removeLocation,
    getDisciplines,
    addDiscipline,
    updateDiscipline,
    addTreatment,
    getTreatment,
    updateTreatment,
    getUserSchedule,
    updateUserSchedule,
    getClinicLocationSchedule,
    getCreditDevices,
    addCreditDevice,
    removeCreditDevice,
    saveLocationAvailability,
  };
};

export default clinicService();

export const transformPreferencesForServer = (val: any) => {
  const omitArray: string[] = functionalDisabilitySurveys
    .filter((item: any) => !val?.disabilitySurveys?.includes(item.id))
    .map((val: any) => val?.id);
  delete val.disabilitySurveys;
  const basicIntakeSurveys = val.basicIntakeSurveys;
  const detailedIntakeSurveys = val.detailedIntakeSurveys;
  delete val.basicIntake;
  delete val.detailedIntake;

  const transformedValues = JSON.stringify(
    Object.keys(val).reduce((obj: any, uiProp) => {
      if (val[uiProp]) {
        obj[uiProp] = true;
      }
      return obj;
    }, {}),
  );
  const sendVal = [
    {
      name: 'New patient preferences',
      type: 'Patient preferences',
      value: transformedValues,
    },
    {
      name: 'Hidden surveys',
      type: 'Patient preferences',
      value: JSON.stringify(omitArray),
    },
    {
      name: 'Custom intakes',
      type: 'Patient preferences',
      value: JSON.stringify({ basicIntakeSurveys, detailedIntakeSurveys }),
    },
  ];

  return sendVal;
};

/**
 * The original transformPreferencesForServer changes everything to
 * a boolean. This leaves the types the way they were originally.
 *
 * @param opts The setting section/type and name.
 * @param data The data/values from the form.
 * @returns The data structure needed to POST an update.
 */
export const formValuesToPayload = (
  opts: { name: string; type: string },
  data: { [key: string]: any },
) => {
  return [
    {
      name: opts.name,
      type: opts.type,
      value: JSON.stringify(data),
    },
  ];
};
