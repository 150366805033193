import {
  BadRequestError,
  BusinessRuleIssueResponse,
  BusinessRuleItemType,
  BusinessRuleSolutionEnum,
  IntegrationInvoice,
  InvoiceStatusEnum,
  STRING_ANY_HASH,
  clog,
} from '@chiroup/core';
import { ChiroUpAPI } from '@chiroup/client-core';
import { invoiceStatusTransition } from './businessRules.service/integrationInvoiceStatusTransition';
import { resolveInvoiceIssues } from './businessRules.service/resolveInvoiceIssues';

const businessRulesService = () => {
  const sanityCheckInvoice = (invoice: IntegrationInvoice) => {
    const issues: BusinessRuleItemType[] = [];
    if (!invoice) return issues;
    if (
      invoice.status === InvoiceStatusEnum.Waiting &&
      invoice.priority === 1
    ) {
      issues.push({
        text: `The primary invoice should not be waiting.`,
        solution: BusinessRuleSolutionEnum.PrimaryInvoiceWaiting,
        tip: [
          'The primary invoice should never be waiting.',
          'Depending on the circumstances, it may be possible to fix this issue.',
        ].join(' '),
      });
    }
    return issues;
  };

  const solve = async ({
    clinicId = -1,
    result = null,
    data = {},
    trace = false,
  }: {
    clinicId: number;
    result: BusinessRuleItemType | null;
    data: any;
    trace?: boolean;
  }) => {
    if (!result) throw new BadRequestError('No result to solve.');
    const body = { trace, solution: { name: result.solution, data } };
    if (trace) clog({ businessRulesService_solve: { result, body } });

    const res: STRING_ANY_HASH = await ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/rules${trace ? '?trace=true' : ''}`,
      { body },
    );

    if (trace) clog({ businessRulesService_solve: { res } });
    return (res as BusinessRuleIssueResponse) ?? null;
  };

  const apply = async ({
    clinicId,
    body,
    trace,
  }: {
    clinicId: number;
    body: any;
    trace?: boolean;
  }) => {
    const res: STRING_ANY_HASH = await ChiroUpAPI.post(
      'api',
      `/transactions/${clinicId}/rules${trace ? '?trace=true' : ''}`,
      { body },
    );
    if (trace) clog({ businessRules: { body, res } });
    return (res as BusinessRuleIssueResponse) ?? null;
  };

  /**
   * TODO: Need to have an invoiceStatusTransitions (plural) go make
   * sure the entire group goes together. [Maybe. The 837P does a loop
   * over them, so a single may be okay.]
   */
  return {
    apply,
    invoiceStatusTransition,
    resolveInvoiceIssues,
    sanityCheckInvoice,
    solve,
  };
};

export default businessRulesService();
